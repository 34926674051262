import React, { useEffect, useState } from "react";
import { BarChart } from "../components/BarChart";
import "./home.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  /*Modal,
  TextField,*/
  Typography,
} from "@mui/material";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import styled from "@emotion/styled";
import DatePicker from "react-datepicker";
import {
  QueryClient,
  QueryClientProvider,
  /*, useMutation, */
} from "react-query";
import { useFetchBubbleData } from "../api/useFetchBubbleData";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SingleVehicle } from "../pdfTemplates/SingleVehicleTemplate";
import { exportToExcel } from "../utils/exportToExcel";
/*
import { useFetchDateRanges } from "../api/useFetchDateRanges";
import axios from "axios";
import { useGeotab } from "../contexts/GeotabContext";*/
import { useFetchVehicles } from "../api/useFetchVehicles";
import { Banner } from "../components/Banner";

//const coreUrl = process.env.REACT_APP_CORE_URL;

const barChartOptionsRate = {
  scales: {
    rate: {
      type: "linear" as const,
      display: true as boolean,
      ticks: {
        color: "#fe803d",
      },
    },
  },
};

const barChartOptions = {
  scales: {
    "y-reimbursement": {
      type: "linear" as const,
      display: true as boolean,
      position: "left" as const,
      ticks: {
        color: "#44c4aa",
      },
    },
    "y-kwh": {
      type: "linear" as const,
      display: true as boolean,
      position: "right" as const,
      ticks: {
        color: "#007BFF",
      },
      grid: {
        drawOnChartArea: false as boolean, // only want the grid lines for one axis to show up
      },
    },
  },
};

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
lastMonth.setDate(1);

// Obtener el último día del mes anterior
const endOfLastMonth = new Date(
  lastMonth.getFullYear(),
  lastMonth.getMonth() + 1,
  0,
);

function formatDate(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

const StyledCard = styled(Card)`
  background-color: white;
  -webkit-box-shadow: rgba(170, 170, 170, 0.2) 0px 2px 16px -2px;
  box-shadow: rgba(170, 170, 170, 0.2) 0px 2px 16px -2px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(241, 241, 241, 0.2);
  border-radius: 8px;
  padding: 24px;
  flex: 1;
  min-height: 50px;
`;

export const Home = ({ queryClient }: { queryClient?: QueryClient }) => {
  const { vehicles, vehiclesError, vehiclesLoading } = useFetchVehicles();
  //const [openModal, setOpenModal] = useState(false);
  //const [dateRangeName, setDateRangeName] = useState("");

  //const { geotabSession } = useGeotab();

  const [selectedVehicle, setSelectedVehicle] = useState(
    vehicles?.[0]?.serialNumber,
  );
  const [dataRange, setDataRange] = useState("month");
  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(endOfLastMonth);
  const [bannerMessage, setBannerMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedVehicle) {
      setSelectedVehicle(vehicles?.[0]?.serialNumber);
    }
  }, [vehicles]);

  const {
    bubbleData,
    bubbleError,
    isBubbleLoading,
    chartData,
    totalKwh,
    average,
    totalReimbursed,
  } = useFetchBubbleData(selectedVehicle, startDate, endDate);

  //const { dateRangesData } = useFetchDateRanges();

  useEffect(() => {
    const reloadOnPopstate = () => {
      // This function will reload the page, using the new URL.
      window.location.reload();
    };

    // Add the event listener when the component mounts.
    window.addEventListener("popstate", reloadOnPopstate);

    // Return a cleanup function that removes the event listener when the component unmounts.
    return () => {
      window.removeEventListener("popstate", reloadOnPopstate);
    };
  }, []);

  const handleChangeDateRange = ({ target: { value } }) => {
    let result;
    if (value === "month") {
      // Restar un mes y establecer al primer día del mes
      result = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    } else if (value === "week") {
      // Restar una semana y establecer al primer día de la semana (domingo)
      result = new Date(today);
      result.setDate(today.getDate() - 7);
      const day = result.getDay();
      result.setDate(result.getDate() - day);
    }
    /* if (value.includes(".custom")) {
      const dateRange = dateRangesData?.find(
        (dateRange) => dateRange.name === value.split(".")[0],
      );
      setDataRange(dateRange.name);
      setStartDate(dateRange.startDate);
      setEndDate(dateRange.endDate);
    }
    setDataRange(value); */
  };

  if (vehiclesLoading) {
    return (
      <Box
        display={"flex"}
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (vehiclesError) {
    return (
      <Box
        display={"flex"}
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
        mt={4}
      >
        <Typography>
          Error fetching data from the server. Please, try again later.
        </Typography>
      </Box>
    );
  }

  if (window.location.origin !== "https://my.geotab.com") {
    window.location.replace(
      "https://my.geotab.com/moveev/#addin-reimburseev-mygeotab_moveev_com",
    );
    return (
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
      >
        <CircularProgress />
        <Typography mt={4}>Redirecting to MyGeotab...</Typography>
      </Box>
    );
  }
  const filename = `${selectedVehicle} Report - ${formatDate(startDate)} to ${formatDate(endDate)}`;

  const selectedVehicleFullInfo = vehicles?.filter(
    (vehicle) => vehicle?.serialNumber === selectedVehicle,
  )[0];

  /*const mutation = useMutation({
    mutationFn: (newDateRange: any) => {
      return axios.post(`${coreUrl}/savedates`, newDateRange);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("dateRanges");
    },
  });*/

  /* useEffect(() => {
    if (dateRangesData && dateRangeName) {
      setDataRange(`${dateRangeName}.custom`);
      setDateRangeName("");
    }
  }, [dateRangesData]); */

  return (
    <Grid container style={{ padding: "0px 20px" }}>
      <Banner 
        message={bubbleData?.message || bubbleData?.alert || bannerMessage} 
        severity={bubbleData?.alert && !bubbleData?.message ? 'warning' : 'info'}
      />
      <Grid
        container
        style={{
          background: "#f7f7f7",
          paddingTop: 20,
          paddingBottom: 20,
          marginBottom: 20,
          display: "flex",
          borderRadius: 10,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        {
          <Grid
            item
            sm={12}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "20px",
              paddingLeft: "20px",
              flex: 1,
              gap: 20,
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-vehicles-label">
                Choose a vehicle:
              </InputLabel>
              <Select
                labelId="select-vehicles-label"
                id="select-vehicles"
                value={selectedVehicle || vehicles?.[0]?.serialNumber}
                label="Choose a Vehicle"
                onChange={(e) => {
                  setSelectedVehicle(e.target.value);
                }}
                placeholder="Choose a Vehicle"
              >
                {vehicles?.map((vehicle) => (
                  <MenuItem key={vehicle.id} value={vehicle?.serialNumber}>
                    {vehicle.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="select-date-range-label">Data Range:</InputLabel>
              <Select
                labelId="select-date-range-label"
                id="select-date-range"
                value={dataRange}
                label={"Data Range"}
                onChange={handleChangeDateRange}
              >
                <MenuItem value="month">Last Month</MenuItem>
                <MenuItem value="week">Last Week</MenuItem>
                <MenuItem value="custom">Custom Date</MenuItem>
                {/* dateRangesData?.map((dateRange) => (
                  <MenuItem
                    key={dateRange.id}
                    value={`${dateRange.name}.custom`}
                  >
                    {dateRange.name}
                  </MenuItem>
                ))*/}
              </Select>
            </FormControl>
            <Grid style={{ width: "100%" }}>
              <InputLabel
                id="select-date-range-label"
                style={{ paddingLeft: 2, paddingBottom: 2 }}
              >
                Start date:
              </InputLabel>
              <FormControl fullWidth>
                <DatePicker
                  selected={startDate}
                  maxDate={endDate}
                  onChange={(value) => {
                    setDataRange("custom");
                    setStartDate(value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <InputLabel
                id="select-date-range-label"
                style={{ paddingLeft: 2, paddingBottom: 2 }}
              >
                End date:
              </InputLabel>
              <FormControl fullWidth>
                <DatePicker
                  selected={endDate}
                  width={"100%"}
                  minDate={startDate}
                  maxDate={today}
                  onChange={(value) => {
                    setDataRange("custom");
                    setEndDate(value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* dataRange === "custom" && (
              <Button onClick={() => setOpenModal(true)}>Save the date</Button>
            )*/}

            {/* <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Name your custom date range
                </Typography>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    size="small"
                    value={dateRangeName}
                    onChange={(e) => setDateRangeName(e.target.value)}
                    fullWidth
                  />
                </Typography>
                <Grid display={"flex"} flex={1} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    disabled={!dateRangeName}
                    onClick={async () => {
                      const name = dateRangeName;
                      await mutation.mutate({
                        email: geotabSession.userName,
                        name: name,
                        startDate: startDate.format("YYYY-MM-DD"),
                        endDate: endDate.format("YYYY-MM-DD"),
                      });
                      setOpenModal(false);
                    }}
                    size="small"
                  >
                    Save
                  </Button>
                </Grid>
              </Box>
            </Modal> */}

            <Box display="flex" alignItems="center">
              <Typography
                fontSize={18}
                fontWeight={600}
                color={"#1851BE"}
                mt={"15px"}
              >
                Metrics
              </Typography>
            </Box>

            <StyledCard
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography color={"#1851BE"} fontWeight={500}>
                Total Reimbursement
              </Typography>
              {isBubbleLoading ? (
                <CircularProgress />
              ) : (
                <Typography fontWeight={"bold"}>
                  {!bubbleError ? `$ ${totalReimbursed}` : "N/A"}
                </Typography>
              )}
            </StyledCard>
            <StyledCard
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography color={"#1851BE"} fontWeight={500}>
                Total kWh Charged
              </Typography>
              {isBubbleLoading ? (
                <CircularProgress />
              ) : (
                <Typography fontWeight={"bold"}>
                  {!bubbleError ? `${totalKwh} kWh` : "N/A"}
                </Typography>
              )}
            </StyledCard>
            <StyledCard
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography color={"#1851BE"} fontWeight={500}>
                Average kWh Charge per Session
              </Typography>
              {isBubbleLoading ? (
                <CircularProgress />
              ) : (
                <Typography fontWeight={"bold"}>
                  {!bubbleError ? `${average || 0} kWh` : "N/A"}
                </Typography>
              )}
            </StyledCard>
            <StyledCard
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography color={"#1851BE"} fontWeight={500}>
                Charging Sessions
              </Typography>
              {isBubbleLoading ? (
                <CircularProgress />
              ) : (
                <Typography fontWeight={"bold"} justifyContent={"center"}>
                  {!bubbleError
                    ? `${bubbleData?.homeChargingEvents?.length}`
                    : "N/A"}
                  <BatteryChargingFullIcon fontSize="small" />
                </Typography>
              )}
            </StyledCard>

            <Typography
              fontWeight="normal"
              justifyContent="left"
              fontSize={13}
              sx={{
                "& a": { textDecoration: "none", color: "blue" },
                "& a:visited": { color: "blue" },
              }}
            >
              Visit the <a href="https://app.moveev.com">MoveEV portal</a> for
              more resources, or contact our{" "}
              <a href="mailto:support@moveev.com">email support team</a> if you
              need assistance.
            </Typography>
          </Grid>
        }
        <Grid
          item
          sm={12}
          md={9}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left", // Centers the child components (the charts)
            width: "70%", // Ensure it takes the full width
            paddingRight: "20px",
            paddingLeft: "20px",
            gap: 2, // Adjust the space between stacked items
          }}
        >
          {
            <Grid
              display={"flex"}
              flex={1}
              direction={"row"}
              justifyContent={{ md: "flex-end" }}
              alignItems={"center"}
              width={"100%"}
            >
              <Button
                style={{
                  backgroundColor: "#1851BE",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  marginRight: "10px",
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  exportToExcel(
                    selectedVehicleFullInfo,
                    bubbleData?.homeChargingEvents,
                    filename,
                  )
                }
              >
                Export XLS
              </Button>
              <Button
                style={{
                  backgroundColor: "#1851BE",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  textTransform: "capitalize",
                }}
              >
                <PDFDownloadLink
                  aria-disabled={bubbleError || isBubbleLoading}
                  document={
                    <QueryClientProvider client={queryClient}>
                      <SingleVehicle
                        selectedVehicle={selectedVehicleFullInfo}
                        startDate={startDate}
                        endDate={endDate}
                        bubbleData={bubbleData}
                      />
                    </QueryClientProvider>
                  }
                  fileName={`${filename}.pdf`}
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  {() => "Export PDF"}
                </PDFDownloadLink>
              </Button>
            </Grid>
          }
          <Grid
            display={"flex"}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
            flexDirection={"row"}
          >
            <Typography
              fontSize={18}
              fontWeight={600}
              color={"#1851BE"}
              mt={"15px"}
              width={"50%"}
            >
              Dollars Reimbursed &amp; kWh Charged
            </Typography>
          </Grid>
          {/* First Chart */}
          <Box
            sx={{
              my: 0,
              width: "100%", // Ensure it takes the full width
              height: "70%",
              // Specify a height or leave it to content/auto
            }}
          >
            {!bubbleError ? (
              chartData?.reimbursementCharge?.datasets && (
                <BarChart
                  type="bar"
                  data={chartData?.reimbursementCharge}
                  options={{ ...barChartOptions, maintainAspectRatio: false }}
                  error={bubbleData?.alert}
                />
              )
            ) : (
              <Box
                display={"flex"}
                flex={1}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
                mt={4}
              >
                <Typography>
                  Error fetching data from the server. Please, try again later.
                </Typography>
              </Box>
            )}
          </Box>

          <Typography
            fontSize={18}
            fontWeight={600}
            color={"#1851BE"}
            mt={"15px"}
          >
            Electricity Cost per kWh
          </Typography>
          {/* Second Chart */}
          <Box
            sx={{
              my: 0,
              width: "100%", // Ensure it takes the full width
              height: "30%",
            }}
          >
            {!bubbleError ? (
              chartData?.electricityCost?.datasets && (
                <>
                  <BarChart
                    type="line"
                    data={chartData?.electricityCost}
                    options={{
                      ...barChartOptionsRate,
                      maintainAspectRatio: false,
                    }}
                    error={bubbleData?.alert}
                  />
                </>
              )
            ) : (
              <Box
                display={"flex"}
                flex={1}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
                mt={4}
              >
                <Typography>
                  Error fetching data from the server. Please, try again later.
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
