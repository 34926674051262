import React from "react";
import { Alert, Box } from "@mui/material";
import styled from "@emotion/styled";

interface BannerProps {
  message?: string | null;
  html?: boolean;
  severity?: 'info' | 'warning' | 'error' | 'success';
}

const StyledBanner = styled(Box)`
  width: 100%;
  margin-bottom: 16px;
  
  .MuiAlert-root {
    width: 100%;
  }
  
  a {
    color: inherit;
    text-decoration: underline;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Banner: React.FC<BannerProps> = ({ 
  message, 
  html = true,
  severity = 'info'
}) => {
  if (!message) return null;

  return (
    <StyledBanner>
      <Alert 
        severity={severity}
        sx={{
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          message
        )}
      </Alert>
    </StyledBanner>
  );
};
