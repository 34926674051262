import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";

const GeotabContext = createContext({
  geotabApi: null,
  geotabState: null,
  geotabFilters: null,
  geotabSession: {
    userName: "",
    database: "",
    sessionId: "",
    firstName: "",
    lastName: "",
  },
});

interface GeotabProviderProps {
  children: React.ReactNode;
}

interface Session {
  userName: string;
  database: string;
  sessionId: string;
  firstName: string;
  lastName: string;
}

const coreUrl = process.env.REACT_APP_CORE_URL;

export const GeotabProvider: FC<GeotabProviderProps> = ({ children }) => {
  const [geotabApi, setGeotabApi] = useState(global?.geotab?.api);
  const [geotabState, setGeotabState] = useState(global?.geotab?.state);
  const [geotabSession, setGeotabSession] = useState<Session>({
    userName: "",
    database: "",
    sessionId: "",
    firstName: "",
    lastName: "",
  });
  const [geotabFilters, setGeotabFilters] = useState(null);

  useQuery(
    "authenticate",
    async () => {
      const response = await fetch(`${coreUrl}/authenticate`, {
        method: "POST",
        body: JSON.stringify(geotabSession),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data) {
        setGeotabSession({
          ...geotabSession,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
        });
        localStorage.setItem("token", data.token);
      }
    },
    {
      enabled: !!geotabSession.sessionId,
      retry: false,
      onError: (error) => {
        console.error('Authentication error:', error);
      }
    },
  );

  useEffect(() => {
    const apiIntervalId = setInterval(() => {
      if (!geotabApi && global?.geotab?.api) {
        setGeotabApi(global.geotab.api);
      }
    }, 1000); // Check every second

    return () => clearInterval(apiIntervalId);
  }, [geotabApi]);

  useEffect(() => {
    const stateIntervalId = setInterval(() => {
      if (!geotabState && global?.geotab?.state) {
        setGeotabState(global.geotab.state);
      }
    }, 1000); // Check every second

    return () => clearInterval(stateIntervalId);
  }, [geotabApi]);

  useEffect(() => {
    const fetchSession = async () => {
      if (geotabApi) {
        try {
          const session = await new Promise((resolve, reject) => {
            geotabApi.getSession((result) => {
              if (result?.sessionId) {
                resolve(result);
              } else {
                reject(new Error("Session ID not found"));
              }
            });
          });

          setGeotabSession((prevSession) => ({
            ...prevSession,
            ...(session as object),
            firstName: "",
            lastName: "",
          }));
        } catch (error) {
          console.error("Failed to fetch session:", error);
        }
      }
    };

    if (geotabApi) {
      fetchSession();
    }
  }, [geotabApi]); // Rerun when geotabApi updates

  useEffect(() => {
    try {
      if (geotabState) {
        setGeotabFilters(geotabState?.getGroupFilter());
      }
    } catch (error) {
      //console.log("error", error);
    }
  }, [geotabState?.getGroupFilter]);

  return (
    <GeotabContext.Provider
      value={{ geotabApi, geotabState, geotabSession, geotabFilters }}
    >
      {children}
    </GeotabContext.Provider>
  );
};

export const useGeotab = () => useContext(GeotabContext);
