import { useMemo } from "react";
import { useQuery } from "react-query";
import { formatISODate } from "../utils/formatDate";
import { fetchWithInterceptors } from "../utils/axiosInterceptors";
import { useGeotab } from "../contexts/GeotabContext";

const coreUrl = process.env.REACT_APP_CORE_URL;

export const useFetchBubbleData = (selectedVehicle, startDate, endDate) => {
  const { geotabSession } = useGeotab();

  console.log("geotabSession", geotabSession);

  const body = {
    start_date: formatISODate(startDate),
    end_date: formatISODate(endDate),
    serial_no: selectedVehicle,
    database: geotabSession.database,
    domain: "my.geotab.com",
  };

  const fetchBubbleData = async () => {
    try {
      const data = await fetchWithInterceptors(`${coreUrl}/bubbleapi`, {
        method: "POST",
        body: JSON.stringify(body),
      });

      const events = data?.home_charging_events || [];
      
      return {
        homeChargingEvents: events.sort((a, b) => {
          return (
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          );
        }),
        alert: data?.alert,
        message: data?.message
      };
    } catch (error) {
      console.error("Bubble API Error:", error);
      throw new Error("Error fetching charging events. Please try again later.");
    }
  };

  const {
    data: bubbleData,
    error: bubbleError,
    isLoading: isBubbleLoading,
  }: {
    data: {
      homeChargingEvents: {
        timestamp: string;
        KWH: string;
        reimbursement_amount: string;
        cost_per_kwh: string;
      }[];
      alert: string;
      message: string;
    };
    error: boolean;
    isLoading: boolean;
  } = useQuery(
    ["bubbleData", selectedVehicle, startDate, endDate],
    fetchBubbleData,
    {
      enabled: !!selectedVehicle,
    },
  );

  const chartData = useMemo(() => {
    if (!bubbleData || bubbleError) return null;
    const labels = [];
    const kwh = [];
    const reimbursement = [];
    const rate = [];

    if (
      bubbleData?.homeChargingEvents &&
      bubbleData?.homeChargingEvents?.length > 0
    ) {
      bubbleData?.homeChargingEvents.forEach((event) => {
        const timestamp = new Date(event.timestamp).toISOString().split("T")[0];
        labels.push(timestamp);
        kwh.push(event.KWH);
        reimbursement.push(event.reimbursement_amount);
        rate.push(event.cost_per_kwh);
      });
    }

    const reimbursementCharge = {
      labels,
      datasets: [
        {
          label: "Reimbursement $",
          data: reimbursement,
          backgroundColor: "#58cab3",
          yAxisID: "y-reimbursement",
        },
        {
          label: "KWH",
          data: kwh,
          backgroundColor: "#007BFF",
          yAxisID: "y-kwh",
        },
      ],
    };
    const electricityCost = {
      labels,
      datasets: [
        {
          label: "Rate $/kWh",
          data: rate,
          backgroundColor: "#fe803d",
        },
      ],
    };

    return {
      reimbursementCharge,
      electricityCost,
    };
  }, [bubbleData, bubbleError]);

  const totalKwh = bubbleData?.homeChargingEvents?.reduce(
    (acum, current) => acum + +current?.KWH,
    0,
  );
  const average = totalKwh / bubbleData?.homeChargingEvents?.length;
  const totalReimbursed = bubbleData?.homeChargingEvents?.reduce(
    (acum, current) => acum + +current?.reimbursement_amount,
    0,
  );

  return {
    bubbleData,
    isBubbleLoading,
    bubbleError,
    chartData,
    totalKwh: totalKwh?.toFixed(2),
    average: average?.toFixed(2),
    totalReimbursed: totalReimbursed?.toFixed(2),
  };
};
